import './App.scss';
import React, { useState} from 'react';
const createLink = (month) => {
 return `https://www.skyscanner.hu/transport/repuloutak/bud/agp/?adults=2&adultsv2=2&cabinclass=economy&children=0&childrenv2=&destinationentityid=27547484&inboundaltsenabled=false&infants=0&iym=23${month}&originentityid=27539604&outboundaltsenabled=false&oym=23${month}&preferdirects=false&ref=home&rtn=1&selectedoday=01&selectediday=01`;
}

const weatherData = [
    {
        "month": "01",
        "degrees": "17° / 8°",
        "rain": "5 nap"
    },
    {
        "month": "02",
        "degrees": "18° / 8°",
        "rain": "4 nap"
    },
    {
        "month": "03",
        "degrees": "19° / 9°",
        "rain": "4 nap"
    },
    {
        "month": "04",
        "degrees": "21° / 11°",
        "rain": "4 nap"
    },
    {
        "month": "05",
        "degrees": "24° / 14°",
        "rain": "3 nap"
    },
    {
        "month": "06",
        "degrees": "28° / 18°",
        "rain": "1 nap"
    },
    {
        "month": "07",
        "degrees": "30° / 20°",
        "rain": "0 nap"
    },
    {
        "month": "08",
        "degrees": "31° / 21°",
        "rain": "0 nap"
    },
    {
        "month": "09",
        "degrees": "28° / 19°",
        "rain": "1 nap"
    },
    {
        "month": "10",
        "degrees": "24° / 15°",
        "rain": "4 nap"
    },
    {
        "month": "11",
        "degrees": "20° / 11°",
        "rain": "5 nap"
    },
    {
        "month": "12",
        "degrees": "17° / 9°",
        "rain": "5 nap"
    }
];

const months = [
    {
        label: 'Január',
        value: '01',
    },
    {
        label: 'Február',
        value: '02',
    },
    {
        label: 'Március',
        value: '03',
    },
    {
        label: 'Április',
        value: '04',
    },
    {
        label: 'Május',
        value: '05',
    },
    {
        label: 'Június',
        value: '06',
    },
    {
        label: 'Július',
        value: '07',
    },
    {
        label: 'Augusztus',
        value: '08',
    },
    {
        label: 'Szeptember',
        value: '09',
    },
    {
        label: 'Október',
        value: '10',
    },
    {
        label: 'November',
        value: '11',
    },
    {
        label: 'December',
        value: '12',
    }
]

function App() {
    const [selectedMonth, setSelectedMonth] = useState('');
    const searchParams = new URLSearchParams(document.location.search);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    }
  return (
      <div>
          <div className="content-wrapper">
            <div className="container column">
                <div className="top-block">
                <div className="columns">
                    {searchParams.get("name") && <div className="column">
                        <div className="card">
                            <div className="card-content">
                                <div className="subtitle is-6">Kedvezményezett neve:</div>
                                <div className="title is-4">{searchParams.get("name")}</div>
                            </div>
                        </div>
                    </div>}
                  <div className="column">
                      <div className="card">
                          <div className="card-content">
                              <div className="subtitle is-6">Érvényesség:</div>
                              <div className="title is-4">2022/01/01 - 2022/12/31</div>
                          </div>
                      </div>
                  </div>
                  <div className="column">
                      <div className="card">
                          <div className="card-content">
                              <div className="subtitle is-6">Kupon státusza:</div>
                              <div className="title subtitle-blob is-4"><div className="blob green"></div> Érvényes</div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
                <div className="columns">
                    <div className="column">
                        <div className="card">
                            <div className="card-content">
                                {/*<div className="subtitle is-5">Időpont javaslás</div>*/}
                                <div className="field">
                                    <label className="label">Hónap</label>
                                    <div className="control">
                                        <div className="select is-fullwidth">
                                            <select onChange={handleMonthChange} value={selectedMonth}>
                                                <option>Válassz</option>
                                                {months.map(month => (
                                                    <option key={`${month.value}-select-option`} value={month.value}>{month.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <p className="help">Válaszd ki melyik hónapon goldolkozol.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {selectedMonth && (
                    <div className="columns is-centered is-multiline">
                        <div className="column is-4">
                            <div className="card card--with-icon">
                                <div className="card__icon">
                                    <span className="icon-weather"></span>
                                </div>
                                <div className="card__title title is-3 mb-0 has-text-centered">
                                    Időjárás
                                </div>
                                <div className="card-content">
                                    <table className="table is-fullwidth">
                                        <tbody>
                                            <tr>
                                                <td>Hónap</td>
                                                <th>{months.find(month => month.value === selectedMonth).label}</th>
                                            </tr>
                                            <tr>
                                                <td>Eső</td>
                                                <th>{weatherData.find(data => data.month === selectedMonth).rain}</th>
                                            </tr>
                                            <tr>
                                                <td>Időjárás (max / min)</td>
                                                <th>{weatherData.find(data => data.month === selectedMonth).degrees}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <footer className="card-footer">
                                    <a href="https://idojaraskalauz.hu/malaga-idojaras-vizhomerseklet/" target="_blank" className="card-footer-item" rel="noreferrer">További infók</a>
                                </footer>
                            </div>
                        </div>
                        <div className="column is-4">
                            <div className="card card--with-icon">
                                <div className="card__icon">
                                    <span className="icon-plane"></span>
                                </div>
                                <div className="card__title title is-3 mb-0 has-text-centered">
                                    Repjegykeresés
                                </div>
                                <div className="card-content">
                                    <div className="content">
                                        <p>Keress magadnak egy olcsó repjegyet. A legtöbb repjegy közvetlen, de előfordulhat, hogy átszállással olcsóbb.</p>
                                        <p><strong>A keresés gombra kattintva a kiválasztott hónapra fogsz repjegyeket találni.</strong></p>
                                    </div>
                                </div>

                                <footer className="card-footer">
                                    <a href={createLink(selectedMonth)} target="_blank" className="card-footer-item" rel="noreferrer">Keresés</a>
                                </footer>
                            </div>
                        </div>
                        <div className="column is-4">
                            <div className="card card--with-icon">
                                <div className="card__icon">
                                    <span className="icon-book"></span>
                                </div>
                                <div className="card__title title is-3 mb-0 has-text-centered">
                                    Időpont foglalása
                                </div>
                                <div className="card-content">
                                    <div className="content">
                                        <p>Mielőtt megveszed a repjegyed, nézd meg, hogy otthon vannak-e a lakók.</p>
                                        <p><strong>Az alábbi gombok egyikével kérdezz meg minket merre fogunk járni.</strong></p>
                                    </div>
                                </div>

                                <footer className="card-footer">
                                    <a href="http://m.me/dora.heindl" className="card-footer-item">Dóri</a>
                                    <a href="http://m.me/andras.gomez" className="card-footer-item">Andris</a>
                                </footer>
                            </div>
                        </div>
                    </div>
                )}
          </div>
          </div>
      </div>
  );
}

export default App;
